header {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 4rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  z-index: 9999;
}

.header {
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  max-width: 1600px;
  grid-template-columns: repeat(24, 1fr);
  align-items: center;
  height: 4rem;
}

.logo-link {
  grid-column: span 3 / span 3;
  grid-column-start: 3;
}

@media (max-width: 993px) {

  .logo-link {
    grid-column: span 4 / span 4;
  }

  .logo-link {
    grid-column-start: 3;
  }
}

@media (max-width: 768px) {

  .logo-link {
    grid-column: span 5 / span 5;
  }

  .logo-link {
    grid-column-start: 3;
  }
}

@media (max-width: 480px) {

  .logo-link {
    grid-column: span 7 / span 7;
  }

  .logo-link {
    grid-column-start: 2;
  }
}

.logo-link {
  max-height: 4rem;
  position: relative;
}
.logo-link a {
  display: inline-block;
}
.logo-link a img {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.logo-link a:hover img {
  transform: scale(107%);
}
.logo-link .logo {
  max-height: 4rem;
}

.header-nav-container {
  grid-column-start: 8;
  grid-column-end: 23;
  height: 4rem;
  display: block;
}

@media (max-width: 993px) {

  .header-nav-container {
    display: none;
  }
}

.header-button-container {
  grid-column: span 7 / span 7;
  grid-column-start: 19;
  height: 100%;
  justify-self: end;
  display: none;
}

@media (max-width: 993px) {

  .header-button-container {
    display: block;
  }
}
.header-button-container .mobile-nav-button {
  position: relative;
  height: 4rem;
  width: 4rem;
  cursor: pointer;
}
.header-button-container .mobile-nav-button i {
  font-size: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nav {
  display: flex;
  justify-content: flex-end;
  height: 4rem;
  align-items: center;
}

.nav-item {
  cursor: pointer;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  position: relative;
  height: 4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  line-height: 64px;
}
.nav-item + .header-nav-item {
  margin-left: 2rem;
}
.nav-item:hover, .nav-item.active {
  transform: translate(2px, 8px);
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  height: 4.1rem;
  line-height: 66px;
}

.language-dropdown {
  position: absolute;
  display: none;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  top: 64px;
}
.language-dropdown .language-item {
  height: 3rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  line-height: 48px;
}
.language-dropdown .language-item a {
  display: block;
  padding-left: 1rem;
  padding-right: 1rem;
}
.language-dropdown .language-item:hover {
  transform: translate(8px, 0);
}

.lang-switch {
  height: 4rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.nav-lang {
  cursor: pointer;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  position: relative;
  height: 4rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  line-height: 64px;
}
.nav-lang:hover {
  transform: translate(2px, 8px);
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  height: 5rem;
  line-height: 80px;
}
.nav-lang:hover .language-dropdown {
  display: block;
  top: 72px;
}

footer {
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(76 175 80 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.footer {
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  max-width: 1600px;
  grid-template-columns: repeat(24, 1fr);
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.footer-logo-image {
  margin-bottom: 2rem;
  height: auto;
  width: 8rem;
}

.footer-zitat-cell {
  grid-column: span 10 / span 10;
  grid-column-start: 3;
  grid-row-start: 2;
  margin-bottom: 2rem;
}

@media (max-width: 993px) {

  .footer-zitat-cell {
    grid-column-end: 23;
  }
}
.footer-zitat-cell .footer-zitat {
  border-left-width: 4px;
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  padding-left: 1rem;
}
.footer-zitat-cell .footer-zitat .zitat-text {
  margin-bottom: 0.5rem;
  font-size: 1.75rem;
}
.footer-zitat-cell .footer-zitat .zitat-autor {
  font-size: 1rem;
}

.footer-menu-cell {
  grid-column: span 10 / span 10;
  grid-column-start: 14;
  grid-row-start: 2;
  margin-bottom: 2rem;
}

@media (max-width: 993px) {

  .footer-menu-cell {
    grid-column-start: 3;
  }

  .footer-menu-cell {
    grid-column-end: 23;
  }

  .footer-menu-cell {
    grid-row-start: 3;
  }
}
.footer-menu-cell .footer-title {
  margin-bottom: 1rem;
  font-size: 1.375rem;
}
.footer-menu-cell a {
  display: block;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.footer-menu-cell a:hover {
  transform: translate(8px, 0);
}

.sub-footer-bg {
  --tw-bg-opacity: 1;
  background-color: rgb(74 165 78 / var(--tw-bg-opacity));
}

.sub-footer {
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  max-width: 1600px;
  grid-template-columns: repeat(24, 1fr);
}

.sub-footer-container {
  grid-column: span 24 / span 24;
  grid-column-start: 3;
  justify-self: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 100%;
  border-top-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(74 165 78 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.app-main {
  padding-top: 4rem;
  background-image: url("/assets/maertbg1.png"), url("/assets/maertbg2.png");
  background-repeat: repeat-x, repeat-x;
  background-size: 150px 70%, 150px auto;
  background-position-y: 0, calc(70% + 15px);
  background-attachment: fixed;
  filter: saturate(70%);
}
.app-main.home {
  background-image: none;
}

.block-container {
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  max-width: 1600px;
  grid-template-columns: repeat(24, 1fr);
  padding-bottom: 3rem;
}

.main-titel {
  padding: 2rem;
  padding-bottom: 3.5rem;
  font-size: 2.25rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(76 54 59 / var(--tw-text-opacity));
  position: relative;
  grid-column-start: 3;
  grid-column-end: 23;
  background-image: url("/assets/titelbg1.png"), url("/assets/titelbg2.png");
  background-repeat: repeat-x, repeat-x;
  background-size: 80px 75%, 80px auto;
  background-position-y: 0, calc(75% + 10px);
}

.block-intro {
  position: relative;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.block-intro .intro-block-container {
  position: absolute;
  width: 100%;
  top: 50%;
  z-index: 50;
  transform: translate(0, -50%);
}
.block-intro .intro-block-container .block-container {
  position: relative;
}
.block-intro .intro-block-container .block-container .content {
  grid-column-start: 3;
  grid-column-end: 23;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  -webkit-animation: 1s ease-out 0s 1 slideInFromLeft;
          animation: 1s ease-out 0s 1 slideInFromLeft;
}
.block-intro .intro-block-container .block-container .content .heading {
  font-size: 3rem;
  --tw-text-opacity: 1;
  color: rgb(76 175 80 / var(--tw-text-opacity));
  margin-bottom: 1rem;
}
.block-intro .intro-block-container .block-container .content .lead {
  font-size: 1.375rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-bottom: 2rem;
}
.block-intro .intro-block-container .block-container .content .link {
  --tw-bg-opacity: 1;
  background-color: rgb(76 175 80 / var(--tw-bg-opacity));
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 0.125rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.block-intro .intro-block-container .block-container .content .link:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(74 165 78 / var(--tw-text-opacity));
}
.block-intro .background {
  grid-column-start: 1;
  grid-column-end: 25;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 65vh;
}
.block-intro .background .background-overlay {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.6);
}

@-webkit-keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
.block-raster {
  position: relative;
  grid-column-start: 3;
  grid-column-end: 23;
}
.block-raster .item-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}
@media (max-width: 993px) {

  .block-raster .item-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
.block-raster .item-container .item {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  text-align: center;
}
.block-raster .item-container .item .bild {
  margin-bottom: 3rem;
  width: 100%;
  padding-top: 56.25%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.block-raster .item-container .item .titel {
  margin-bottom: 1rem;
  font-size: 1.75rem;
  line-height: 1.25;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
.block-raster .item-container .item .text {
  margin-bottom: 1.5rem;
  font-size: 1rem;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
.block-raster .item-container .item .link {
  --tw-bg-opacity: 1;
  background-color: rgb(76 175 80 / var(--tw-bg-opacity));
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  margin-top: 1.5rem;
  border-radius: 0.125rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.block-raster .item-container .item .link:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(74 165 78 / var(--tw-text-opacity));
}
.block-raster .item-container .item .bild {
  border-radius: 0.125rem;
}

.block-bildMitText {
  position: relative;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.block-bildMitText .bildMitText-block-container {
  position: absolute;
  width: 100%;
  top: 50%;
  z-index: 50;
  transform: translate(0, -50%);
}
.block-bildMitText .bildMitText-block-container .block-container {
  position: relative;
}
.block-bildMitText .bildMitText-block-container .block-container .content {
  grid-column-start: 3;
  grid-column-end: 23;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  -webkit-animation: 1s ease-out 0s 1 slideInFromLeft;
          animation: 1s ease-out 0s 1 slideInFromLeft;
}
.block-bildMitText .bildMitText-block-container .block-container .content .heading {
  font-size: 1.375rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.block-bildMitText .background {
  grid-column-start: 1;
  grid-column-end: 25;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 40vh;
}
.block-bildMitText .background .background-overlay {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.6);
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
.block-karte {
  position: relative;
  grid-column-start: 3;
  grid-column-end: 23;
}
.block-karte .heading {
  margin-bottom: 1rem;
  text-align: center;
  font-size: 1.75rem;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
.block-karte .google-maps {
  width: 100%;
  height: 500px;
}

.block-aktuelles {
  position: relative;
  grid-column-start: 3;
  grid-column-end: 23;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.block-aktuelles .item-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}
@media (max-width: 993px) {

  .block-aktuelles .item-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
.block-aktuelles .item-container .item {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.block-aktuelles .item-container .item .bild {
  width: 100%;
  padding-top: 56.25%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.block-aktuelles .item-container .item .content {
  padding: 2rem;
}
.block-aktuelles .item-container .item .content .titel {
  margin-bottom: 1rem;
  display: block;
  font-size: 1.375rem;
  background-image: none;
}
.block-aktuelles .item-container .item .content .datum {
  margin-bottom: 1rem;
  font-size: 1rem;
}
.block-aktuelles .item-container .item .content .text {
  font-size: 1rem;
}
.block-aktuelles .item-container .item .content .links {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0.5rem;
  border-top-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(76 175 80 / var(--tw-border-opacity));
  padding-top: 2rem;
}
.block-aktuelles.aktuelles-entry {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 993px) {

  .block-aktuelles.aktuelles-entry {
    grid-template-columns: repeat(1, 1fr);
  }
}
.block-aktuelles.aktuelles-entry .bild {
  width: 100%;
  padding-top: 56.25%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.block-aktuelles.aktuelles-entry .content {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 2rem;
}
.block-aktuelles.aktuelles-entry .content .datum {
  margin-bottom: 1rem;
  font-size: 1.375rem;
}
.block-aktuelles.aktuelles-entry .content .text {
  font-size: 1rem;
}
.block-aktuelles.aktuelles-entry .content .links {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0.5rem;
  border-top-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(76 175 80 / var(--tw-border-opacity));
  padding-top: 2rem;
}

.block-maertfahrer {
  position: relative;
  grid-column-start: 3;
  grid-column-end: 23;
}
.block-maertfahrer .item-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}
@media (max-width: 993px) {

  .block-maertfahrer .item-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
.block-maertfahrer .item-container .item {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.block-maertfahrer .item-container .item .bild {
  width: 100%;
  padding-top: 56.25%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.block-maertfahrer .item-container .item .content {
  position: relative;
  padding: 2rem;
}
.block-maertfahrer .item-container .item .content .titel {
  margin-bottom: 1rem;
  display: block;
  font-size: 1.375rem;
}
.block-maertfahrer .item-container .item .content .text {
  font-size: 1rem;
}
.block-maertfahrer .item-container .item .content .links {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0.5rem;
  border-top-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(76 175 80 / var(--tw-border-opacity));
  padding-top: 2rem;
}

.block-galerie {
  position: relative;
  grid-column-start: 3;
  grid-column-end: 23;
}
.block-galerie .galerie-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;
}
.block-galerie .galerie-container .galerie {
  position: relative;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 2rem;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.block-galerie .galerie-container .galerie .titel {
  margin-bottom: 1rem;
  font-size: 1.375rem;
}
.block-galerie .galerie-container .galerie .text {
  margin-bottom: 1rem;
  font-size: 1rem;
}
.block-galerie .galerie-container .galerie .gallery {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.block-galerie .galerie-container .galerie .gallery .bild {
  position: relative;
  padding-top: 56.25%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  float: left;
  width: 50%;
  border-radius: 0.125rem;
  --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  margin-left: -25%;
}
.block-galerie .galerie-container .galerie .gallery .bild .filter-layer {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 0.125rem;
  background-color: rgba(46, 59, 78, 0.5);
}
.block-galerie .galerie-container .galerie .gallery .bild .open-button {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 3rem;
  width: 3rem;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  transform: translate(-50%, -50%);
}
.block-galerie .galerie-container .galerie .gallery .bild:first-child {
  margin-left: 0;
}

.lightbox {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.9);
}
.lightbox .close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: 3rem;
  width: 3rem;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.lightbox .prev-button {
  position: absolute;
  left: 1rem;
  top: 50%;
  height: 3rem;
  width: 3rem;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  transform: translate(0, -50%);
}
.lightbox .next-button {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translate(0, -50%);
  height: 3rem;
  width: 3rem;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.lightbox .lightbox-container {
  position: absolute;
  right: 5rem;
  left: 5rem;
  top: 50%;
  height: 75%;
  max-height: 100%;
  max-width: 100%;
  transform: translate(0, -50%);
}
.lightbox .lightbox-container .lightbox-bild {
  height: 100%;
  width: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.lightbox .lightbox-container .lightbox-text {
  position: relative;
  margin-top: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
  text-align: center;
  font-size: 1.375rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.lightbox .lightbox-container .lightbox-number {
  position: absolute;
  right: 0px;
  top: 0px;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-to,
.v-leave {
  opacity: 1;
}

.v-enter,
.v-leave-to {
  opacity: 0;
}

.block-kontaktformular {
  position: relative;
  grid-column-start: 3;
  grid-column-end: 23;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 2rem;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.block-kontaktformular .titel {
  margin-bottom: 1rem;
  font-size: 1.375rem;
}
.block-kontaktformular .kontakt-form label {
  margin-top: 1rem;
  display: block;
}
.block-kontaktformular .kontakt-form input {
  width: 100%;
  padding: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.39), 0 -1px 1px #ffffff, 0 1px 0 #ffffff;
  border-radius: 0.125rem;
  border-bottom-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(76 175 80 / var(--tw-border-opacity));
  margin-bottom: 1.5rem;
}
.block-kontaktformular .kontakt-form textarea {
  width: 100%;
  padding: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.39), 0 -1px 1px #ffffff, 0 1px 0 #ffffff;
  border-radius: 0.125rem;
  border-bottom-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(76 175 80 / var(--tw-border-opacity));
  margin-bottom: 1.5rem;
}
.block-kontaktformular .kontakt-form button {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(76 175 80 / var(--tw-border-opacity));
  border-radius: 0.125rem;
  --tw-bg-opacity: 1;
  background-color: rgb(76 175 80 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.block-kontaktformular .kontakt-form button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(76 175 80 / var(--tw-text-opacity));
}
.block-kontaktformular .kontakt-form .errors {
  margin-top: 0.5rem;
  font-size: 1rem;
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.block-text {
  position: relative;
  grid-column-start: 3;
  grid-column-end: 23;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 2rem;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.block-text a {
  --tw-text-opacity: 1;
  color: rgb(76 175 80 / var(--tw-text-opacity));
}
.block-text a:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.block-text p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.block-text i, .block-text em {
  font-weight: 500;
}
.block-text blockquote {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: 1.5rem;
  --tw-border-opacity: 1;
  border-color: rgb(76 175 80 / var(--tw-border-opacity));
  padding-left: 0.75rem;
  border-left-width: 3px;
  font-style: italic;
}
.block-text ul, .block-text ol {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: 2.25rem;
}
.block-text ul > li + li, .block-text ol > li + li {
  margin-top: 0.25rem;
}
.block-text ul > li > ol, .block-text ul > li > ul, .block-text ol > li > ol, .block-text ol > li > ul {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.block-text ul {
  list-style-type: disc;
}
.block-text ol {
  list-style-type: decimal;
}
.block-text table tr td {
  padding: 0.25rem;
  padding-right: 1rem;
}
.block-text .titel {
  margin-bottom: 1rem;
  font-size: 1.375rem;
}

.overlay {
  position: fixed;
  overflow: hidden;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.2s, opacity 0.2s linear 0s;
  pointer-events: none;
}
.overlay.overlay-show {
  pointer-events: all;
  z-index: 9998;
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 0.2s linear 0s;
}
.overlay.overlay-navigation-mobile {
  top: 4rem;
  right: 0px;
  height: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.overlay.overlay-navigation-mobile .mobile-nav {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
}
.overlay.overlay-navigation-mobile .mobile-nav a {
  display: block;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1.375rem;
}
.overlay.overlay-navigation-mobile .mobile-nav a:hover {
  --tw-text-opacity: 1;
  color: rgb(76 175 80 / var(--tw-text-opacity));
}
.overlay.overlay-navigation-mobile .mobile-nav a.active {
  --tw-text-opacity: 1;
  color: rgb(76 175 80 / var(--tw-text-opacity));
}

.overlay.overlay-navigation-desktop {
  top: 0px;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(24, 1fr);
  overflow: visible;
  margin-top: 69px;
  grid-template-rows: -webkit-max-content -webkit-max-content;
  grid-template-rows: max-content max-content;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  transition: visibility 0s linear 0.2s, opacity 0.2s linear 0s, z-index 0s linear 0.2s;
}
.overlay.overlay-navigation-desktop.overlay-show {
  z-index: 10000;
  transition: visibility 0s linear 0s, opacity 0.2s linear 0s, z-index 0s linear 0s;
}
.overlay.overlay-navigation-desktop > .overlay-inner {
  max-width: none;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  position: relative;
  grid-column: span 22 / span 22;
  grid-column-start: 3;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-top: 1px solid #f3f3f3;
}
.overlay.overlay-navigation-desktop .nav-overlay-outer-grid {
  display: grid;
  grid-template-columns: repeat(22, 1fr);
}
.overlay.overlay-navigation-desktop .nav-overlay-cell {
  grid-column: span 16 / span 16;
  grid-column-start: 4;
}
@media (max-width: 1600px) {

  .overlay.overlay-navigation-desktop .nav-overlay-cell {
    grid-column: span 17 / span 17;
  }

  .overlay.overlay-navigation-desktop .nav-overlay-cell {
    grid-column-start: 4;
  }
}
.overlay.overlay-navigation-desktop .nav-overlay-innner-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  -moz-column-gap: 4rem;
       column-gap: 4rem;
  row-gap: 1.25rem;
}
.overlay.overlay-navigation-desktop .nav-overlay-group-title {
  display: flex;
  height: 2rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  --tw-border-opacity: 1;
  border-color: rgb(1 156 218 / var(--tw-border-opacity));
  padding-bottom: 0.25rem;
  border-bottom-width: 1px;
}
.overlay.overlay-navigation-desktop .nav-overlay-group a {
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.overlay.overlay-navigation-desktop .nav-overlay-group a:hover {
  --tw-text-opacity: 1;
  color: rgb(76 175 80 / var(--tw-text-opacity));
}
.overlay.overlay-navigation-desktop .nav-overlay-group a:hover > div {
  text-decoration: underline;
}